import React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';

const DashboardLayout = ({
  navBar: NavBar,
  children,
  mainTagClassName,
  title
}) => {
  return (
    <div className='grid grid-cols-[1fr,6fr] bg-white min-h-screen text-gray-500 tab-land:grid-cols-none tab-land:auto-rows-min'>
      <Helmet>
        <title>TheCRO &mdash; {title}</title>
      </Helmet>

      <NavBar />

      <main className={classNames('bg-gray-100', mainTagClassName)}>
        {children}
      </main>
    </div>
  );
};

export default DashboardLayout;
