import React from 'react';
import { Link } from 'gatsby';

const SidebarLink = ({ children, href, icon: Icon }) => (
  <li>
    <Link
      partiallyActive={true}
      to={href}
      className='relative flex items-center tab-land:justify-center'
      activeClassName='font-semibold text-gray-800 before:h-2 before:w-2 before:absolute before:bg-primary before:rounded-full before:right-10 before:top-1/2 before:-translate-y-1/2 tab-land:before:hidden tab-land:text-primary'>
      <Icon className='fill-current h-6' />
      <span>{children}</span>
    </Link>
  </li>
);

export default SidebarLink;
