import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Formik } from "formik";

import Input from "$ui/input";
import Choice from "$ui/choice";
import Heading from "$ui/heading";
import Anchor from "$ui/anchor";
import Table from "$ui/table";
import Button from "$ui/button";

import AdminLayout from "$components/layout/admin";

import { getAllComplaints } from "$utils/queryFunctions";
import flattenObject from "$utils/flattenObject";

const statusTypes = [
  "PENDING",
  "ACCEPTED",
  "DECLINED",
  "PAID",
  "UNPAID",
  "RESOLVED",
];

const AdminComplaintsPage = () => {
  const { data: queryData } = useQuery("complaints", getAllComplaints);
  const [complaints, setComplaints] = useState([]);

  useEffect(() => {
    setComplaints(queryData);
  }, [queryData]);

  const handleFiltering = useCallback(
    (userInputs) =>
      setComplaints(
        queryData.filter((cur) => {
          // Bring nested fields on top level to make search easier
          const row = flattenObject(cur);

          // "Array.prototype.every"  will return true if all fields in current row matches with userInputs
          return Object.entries(userInputs).every(([key, inputValue]) => {
            // Find corresponding queryData value from userInputs
            const valueInData = (
              row[key] || row[`opposition.${key}`]
            )?.toLowerCase();

            // Return true if dataValue for given key includes inputValue
            return valueInData.includes(inputValue.toLowerCase());
          });
        })
      ),
    [queryData, setComplaints]
  );

  return (
    <AdminLayout title="Admin Account">
      <section className="p-10 phone:px-5 h-screen overflow-y-auto">
        <Heading variant="tertiary-left">Filters</Heading>

        <Formik
          onSubmit={handleFiltering}
          initialValues={{
            id: "",
            state: "",
            city: "",
            pincode: "",
            issueType: "",
            status: "",
          }}
        >
          {(formik) => (
            <form
              autoComplete="off"
              onSubmit={formik.handleSubmit}
              className="mt-4 mb-10 bg-white px-5 py-5 grid items-center gap-y-5 gap-x-10 grid-cols-[repeat(auto-fit,minmax(10rem,1fr))]"
            >
              <Input
                name="id"
                placeholder="complaint id"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Input
                name="state"
                placeholder="state"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Input
                name="city"
                placeholder="city"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Input
                name="pincode"
                placeholder="pincode"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Choice
                name="issueType"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Issue Type</option>
                <option value="medical">medical</option>
                <option value="insurance">insurance</option>
                <option value="real-estate">real-estate</option>
                <option value="online">online</option>
                <option value="hotel">hotel</option>
                <option value="transportation">transportation</option>
                <option value="food-packaging">food-packaging</option>
                <option value="other">other</option>
              </Choice>
              <Choice
                name="Complained To Seller/ Vendor / Government"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">
                  Complained To Seller/ Vendor / Government.
                </option>
                <option value="no">No</option>
                <option value="yes">Yes</option>
              </Choice>
              <Choice
                name=" Complained To Anyother Platform"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Complained To Anyother Platform</option>
                <option value="no">No</option>
                <option value="yes">Yes</option>
              </Choice>
              <Choice
                name="Transaction / Incident Place"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Transaction / Incident Place</option>
                <option value="At the firm's place of business">
                  At the firm's place of business
                </option>
                <option value="By email">By email</option>
                <option value="My home">My home</option>
                <option value="By Internet / Email">By Internet / Email</option>
              </Choice>
              <Choice
                name="Resolution"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Resolution</option>
                <option value="Refund">Refund</option>
                <option value="Replacement">Replacement</option>
                <option value="Apology">Apology</option>
                <option value="Compensation">Compensation</option>
              </Choice>
              <Choice
                name="Transaction For"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">What Was The Transaction For?</option>
                <option value="My Business">My Business</option>
                <option value="Family">Family</option>
                <option value="Other">Other</option>
              </Choice>
              <Choice
                name="Consent To Disclose To the Public"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Consent To Disclose To the Public?</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Choice>
              <Choice
                name="Payment Method"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Payment Method</option>
                <option value="Cash">Cash</option>
                <option value="Cheque">Cheque</option>
                <option value="Credit Card">Credit Card</option>
                <option value="Installment Loan">Installment Loan</option>
                <option value="Medicare">Medicare</option>
                <option value="Private Insurance">Private Insurance</option>
                <option value="Other">Other</option>
              </Choice>
              <Choice
                name="Sign Agreement"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Sign Agreement</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Choice>
              <Choice
                name="Filed Complaint With Other Agencies"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Filed Complaint With Other Agencies?</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Choice>
              <Choice
                name="Contacted Private Lawyer"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Contacted Private Lawyer?</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Choice>
              <Choice
                name="Court Action"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Court Action?</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Choice>

              <Input
                name="loss-amount"
                placeholder="Loss Amount"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />

              <Input
                name="transaction-date"
                placeholder="Transaction/Incident Date"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="date"
              />

              <Choice
                name="status"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Status</option>
                {statusTypes.map((status) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </Choice>

              <div className="col-span-full text-right">
                <Button variant="filled" type="submit">
                  Apply filters
                </Button>
              </div>
            </form>
          )}
        </Formik>

        <Heading variant="tertiary-left">All Complaints</Heading>

        <Table
          className="phone:text-atmoic"
          headers={[
            "complaint id",
            "date",
            "state",
            "city",
            "type",
            "status",
            "",
          ]}
        >
          {complaints?.map((c, i) => (
            <tr key={c.id}>
              <td>{c.id}</td>
              <td>{new Intl.DateTimeFormat().format(new Date(c.createdAt))}</td>
              <td>{c.opposition.state}</td>
              <td>{c.opposition.city}</td>
              <td>{c.issueType}</td>
              <td>{c.status}</td>
              <td>
                <Anchor
                  size="small"
                  href={`/admin/complaints/${c.id}`}
                  variant="outlined-colored"
                >
                  Details &nbsp; &rarr;
                </Anchor>
              </td>
            </tr>
          ))}
        </Table>
      </section>
    </AdminLayout>
  );
};

export default AdminComplaintsPage;
