import React from 'react';

const Choice = ({ children, ...rest }) => (
  <select
    className='text-gray-500 outline-none py-3 border-b-2 border-gray-400 bg-transparent'
    {...rest}>
    {children}
  </select>
);

export default Choice;
