import React from 'react';
import classNames from 'classnames';

const Table = ({ headers, children, className }) => {
  return (
    <table className={classNames('w-full', className)}>
      <thead className='capitalize'>
        <tr>
          {headers?.map((header, i) => (
            <td className='border-b-2 font-semibold' key={i}>
              {header}
            </td>
          ))}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};

export default Table;
