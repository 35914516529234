import React from 'react';
import classNames from 'classnames';

const Bar = props => (
  <div
    className='w-4 h-20 bg-gray-300'
    style={{
      animation: `enlarge .7s infinite ${props.animationDelay}s`
    }}></div>
);

// NOTE: THe Loader has to wrapped inside another DIV because of some very stupid gatsby glitch
// Gatsby is reomving the first element of this component
const Loader = props => (
  <div>
    <div
      className={classNames(
        props.height || 'h-screen',
        'col-span-full flex flex-col justify-center items-center space-y-12 bg-white'
      )}>
      <div className='flex space-x-6'>
        {new Array(6).fill('*').map((_, i) => (
          <Bar animationDelay={i / 10} key={i} />
        ))}
      </div>
      <h2 className='text-2xl text-gray-400 tracking-widest'>Loading...</h2>
    </div>
  </div>
);

export default Loader;
