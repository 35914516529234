import React from 'react';
import { navigate } from 'gatsby';

import PageLoader from '$components/pageLoader';

import { useUser } from '$hooks/auth';
import { logout, isBrowser } from '$services/auth';

const ProtectedRoute = ({ children, variant = 'user' }) => {
  const { user, isLoading } = useUser();

  if (isLoading) return <PageLoader />;

  if (!user && isBrowser() && !window?.location.href.includes('/login')) {
    logout(() => navigate('/auth/login'));
    return <PageLoader />;
  }

  if (
    (variant === 'user' || variant === 'member') &&
    !['user', 'member'].includes(user?.role)
  ) {
    isBrowser() && navigate('/admin/dashboard');
    return <PageLoader />;
  }

  if (variant === 'super-admin' && user?.role !== 'super-admin') {
    isBrowser() && navigate('/me/dashboard');
    return <PageLoader />;
  }

  return children;
};

export default ProtectedRoute;
